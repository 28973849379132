.info-modal {
    .modal-body {
        pre {
            word-break: break-word;
            white-space: normal;
        }
    }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  &.info-modal-for-unique-or-specify-name {
    .second-button {
      order: -1;
    }
    .btn {
      margin-left: 5px;
    }
  }
}

.create-new-config-item {
    display: flex;
    align-items: center;
    padding: 16px 6px;

    .item-label {
        min-width: 180px;
    }

    .input {
        width: 100%;
        outline-color: transparent;
        transition: 0.2s;
        border: 1px solid #cccccc;
        height: 38px;
        border-radius: 4px;
        padding: 2px 8px;

        &:hover {
            border-color: #b3b3b3;
        }

        &:focus {
            box-shadow: 0 0 0 1px #2684FF;
            border-color: #2684FF;
        }
    }

    .select {
        width: 100%;
    }

    .item-content {
        min-height: 38px;
        width: 225px;
    }

    .select-dropdown__menu {
        max-height: 300px;
        position: fixed;
        top: auto;
        left: auto;
        right: auto;
        width: 225px;
    }
}