.App {
  flex: 100%;
  display: flex;
  flex-flow: column nowrap;
  font-family: Open Sans;
  font-weight: 400;
  max-width: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.ReactCodeMirror {
  flex: 1;
  height: 100%;
  width: 100%;
}

.MainPanel {
  height: 100%;
  z-index: 0;
  max-width: 100%;
  overflow: hidden;
}

.MainPanel .form-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  margin-bottom: 0;
}

.MainPanel .import-config-label {
  display: block;
  width: 100%;
  padding: 7px 10px 6px 35px;
  position: relative;
  font-size: 14px;
  font-weight: bold;
  z-index: 3;
  flex: 0;
  border-bottom: 1px solid #ddd;
}

.MainPanel .import-config-label:after {
  content: '';
  position: absolute;
  top: calc(100% + 1px);
  left: 30px;
  right: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.MainPanel .CodeMirror {
  min-height: 100%;
}

.Main-container {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 1em 0;
  padding: 0 2em;
  width: 100%;
  height: 100%;
}

.Main {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}

.Main-container:after,
.Main-container:before {
  content: '';
  position: absolute;
  background: #fff;
  top: 0;
  height: 100%;
  width: 2em;
  z-index: 3;
}

.Main-container:after {
  right: 0;
}

.Main-container:before {
  left: 0;
}

.preformatted {
  font-family: monospace;
  white-space: pre;
}

.Entity {
  padding: 2em;
  background-color: #eee;
}

.Type {
  padding: 2em;
  background-color: #eee;
}

.Attribute {
  padding: 2em;
  background-color: #ddd;
}

.btn {
  outline-color: transparent !important;
  line-height: 1.5;
}

 .btn-default:focus,
 .btn-default:active {
   color: #333;
   background-color: #fff;
   border-color: #ccc;
   outline-width: 0 !important;
 }

.disabled-btn {
  color: grey !important;
  pointer-events: none !important;
}

/*DO NOT DISPLAY THE + GLYPHICON*/
.btn-add i.glyphicon { display: none; }

/*ADD BUTTONS ARE JUSTIFIED TO LEFT WITHOUT SET WIDTH*/
.row.array-item-list + .row .array-item-add {
  width: unset;
  margin-left: unset;
}

/*CUSTOM LABELS FOR THE DIFFERENT 'ADD' BUTTONS*/
.Attributes-Array > .field-array > .row > p.array-item-add > button.btn-add::after {
  content: 'Add Attribute';
}

.Entities-Array > .field-array > .row > p.array-item-add > button.btn-add::after {
  content: 'Add Entity';
}

.Types-Array > .field-array > .row > p.array-item-add > button.btn-add::after {
  content: 'Add Type';
}

.Relations-Array > .field-array > .row > p.array-item-add > button.btn-add::after {
  content: 'Add Relation';
}

.EntityPermissions-Array > .field-array > .row > p.array-item-add > button.btn-add::after {
  content: 'Add Entity Permissions';
}

.AttributePermissions-Array > .field-array > .row > p.array-item-add > button.btn-add::after {
  content: 'Add Attribute Permissions';
}

.RelationPermissions-Array > .field-array > .row > p.array-item-add > button.btn-add::after {
  content: 'Add Relation Permissions';
}

/* SPLITTER LAYOUT STYLES */
.OutherPane > .layout-pane:not(.layout-pane-primary) {
  padding: 0 1em 0 0;
}

.splitter-layout.OutherPane {
  left: 0;
  right: 0;
}

.splitter-layout .layout-splitter {
  position: relative;
}

.splitter-layout .layout-splitter:after,
.splitter-layout .layout-splitter:before {
  content: '';
  position: absolute;
  background: #fff;
  top: 0;
  height: 100%;
  width: calc(1em - 1px);
  z-index: 3;
}

.splitter-layout .layout-splitter:before {
  left: 100%;
}

.splitter-layout .layout-splitter:after {
  right: 100%;
}

.InnerPane > .layout-pane:not(.layout-pane-primary) {
  padding: 0 0 0 1em;
}

.Main .splitter-layout .layout-pane {
  position: relative;
  overflow: hidden;
}

.Main .splitter-layout.InnerPane.InnerPane-graph .layout-pane:last-child,
.Main .splitter-layout.OutherPane.OutherPane-navigation > .layout-pane:first-child {
  z-index: 3;
  background: #fff;
}

.Main .splitter-layout.InnerPane.InnerPane-graph > .layout-pane:first-child + .layout-splitter {
  margin-left: 1em;
}

.Main .splitter-layout.OutherPane.OutherPane-navigation > .layout-pane:first-child + .layout-splitter {
  margin-right: 1em;
}

.splitter-layout .layout-splitter {
  z-index: 4;
}

.Main .splitter-layout.OutherPane.OutherPane-navigation .layout-pane:first-child {
  min-width: 235px;
}

.splitter-layout *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.splitter-layout *::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px #ccc;
}

.splitter-layout *::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  background-color: grey;
}

.splitter-layout *::-webkit-scrollbar-thumb:hover {
  background-color: #4b4b4b;
}

.layout-pane::-webkit-scrollbar {
  width: 14px;
}

.layout-pane::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #ccc;
}

.layout-pane::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: #ccc;
  outline: 1px solid #ccc;
}

.information-block {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.information-block-content {
  max-width: 560px;
  width: 100%;
  text-align: center;
}

.information-block-title {
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 15px;
}

.log-out-button {
  min-width: 100px;
}
