.App__Header {
  display: flex;
  align-items: center;
  color: #3D515B;
  border-bottom: 2px solid #DA2C20;
  flex: 0 0 auto;
  z-index: 5;
}

.App__HeaderLogo {
  width: 50px;
  height: 50px;
  margin: 1em;
}

.App__HeaderLabel {
  font-size: 2em;
  font-weight: 300;
  flex: 1;
}

.App__TenantSelection {
  margin-right: 1em;
  display: flex;
  align-items: center;
}

.App__TenantSelectionLabel {
  margin-right: 1em;
}

.App__TenantSelectionDropdown {
  width: 10em;

  .SelectionDropdown__indicators {
    svg {
      transition: 0.1s;
    }
  }
  .SelectionDropdown__control--menu-is-open {
      .SelectionDropdown__indicators {
        svg {
          transform: rotate(180deg);
        }
    }
  }
}

.App_Logout .App_Logout_btn{
  margin-right: 50px;
}

.modal-with-jenkins-version {
  position: relative;
  padding: 10px 0 10px 60px;

  .image {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 50px;
  }
}
