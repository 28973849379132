.global-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0, 0.3);
  transition: 0.2s linear 0.5s;
  z-index: -10;
  opacity: 0;
  visibility: hidden;
}

.global-loader.show {
  z-index: 1000;
  opacity: 1;
  visibility: visible;
}